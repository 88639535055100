import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function loginWithToken(loginToken) {
  return new NetworkRequestConfiguration({
    endpoint: 'login/token',
    method: NetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      loginToken,
    },
    successCodes: [201],
    errorCodes: [401],
  });
}
